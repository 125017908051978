import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../Components/CustomTextField";
import CustomLoader from "../Components/CustomLoader";
import logo from "../Assets/logo.png";
import AxiosInstance from "../Config/AxiosInstance";
import { ApiList } from "../Config/ApiList";
import { toast } from "react-toastify";
import PathList from "../Common/PathList";
import { MyContext } from "../Context/UiContext";

const LoginScreen = () => {
  const { state } = useContext(MyContext);
  const form = state.find((item) => {
    if (item.form_type === "Form") {
      return item;
    }
  });
  const isFieldInGrid = (fieldName) => {
    return form?.fields?.some((gridItem) => gridItem.column_name === fieldName);
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [forgotpassword, setrForgotpassword] = useState(false);
  useEffect(() => {
    let userinfo;
    try {
      userinfo = JSON.parse(localStorage.getItem("nextdoor-admin"));
    } catch (error) {
      userinfo = null;
    }
    if (userinfo) {
      return navigate(PathList.UserManagement);
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email address is required!"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password must be at most 20 characters"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        const auth_token = "oCQMU37ZqibvPP8b06Vxps2mfAt1";
        const formData = new FormData();
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("auth_token", auth_token);
        const method = form?.api_method?.toLowerCase();
        const apiEndpoint = form?.api;
        const response = await AxiosInstance.post(apiEndpoint, formData);
        if (response && response?.data?.s === 1) {
          const { role } = response?.data?.r;
          if (role === 2) {
            localStorage.setItem(
              "nextdoor-admin",
              JSON.stringify(response?.data?.r)
            );
            navigate(PathList.UserManagement);
            toast.success("Login Successfully.");
            resetForm();
          } else {
            toast.error("You Are Not Authenticated");
          }
        } else {
          toast.error(response.data.m);
          resetForm();
        }
      } catch (error) {
        console.log(error);
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const ForgotPasswordValidation = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email address is required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        const params = {
          email: values.email,
          role: 2,
        };
        const response = await AxiosInstance.post(
          ApiList.FORGOT_PASSWORD,
          params
        );
        if (response && response?.data?.s === 1) {
          toast.success(response.data.m);
          resetForm();
        } else {
          toast.error(response.data.m);
          resetForm();
        }
      } catch (error) {
        console.log(error);
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <>
      <Box
        sx={
          {
            // backgroundImage: "url(/login.png  )",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "100% 100%",
          }
        }
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 2,
            background: "#306B4B",
          }}
        >
          <Box
            sx={{
              width: { xs: "95%", sm: "60%", md: "50%", lg: "40%", xl: "30%" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                bgcolor: "white",
                borderRadius: "30px",
                py: 1,
                position: "relative",
                zIndex: "2",
              }}
            >
              {forgotpassword ? (
                <Box sx={{ height: "100%", width: "100%", p: 3 }}>
                  <Typography sx={{ fontSize: "25px", fontWeight: "500" }}>
                    Forget Password?
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#000000B2" }}>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  </Typography>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      ForgotPasswordValidation.handleSubmit();
                      return false;
                    }}
                    style={{
                      width: "100%",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "1.5rem",
                      paddingTop: "30px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <CustomTextField
                        label="Email Address"
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        value={ForgotPasswordValidation.values.email}
                        onBlur={ForgotPasswordValidation.handleBlur}
                        onKeyUp={ForgotPasswordValidation.handleBlur}
                        errors={
                          ForgotPasswordValidation.touched.email &&
                          ForgotPasswordValidation.errors.email
                        }
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.trimStart() !== value) {
                            e.target.value = value.trimStart();
                          }
                          ForgotPasswordValidation.handleChange(e);
                        }}
                        // isLoading={isLoading}
                      />
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", gap: "20px" }}>
                      <CustomLoader
                        btnTitle="Back"
                        type="button"
                        onSubmit={() => setrForgotpassword(false)}
                      />
                      <CustomLoader
                        btnTitle="Submit"
                        loading={isLoading}
                        type="submit"
                      />
                    </Box>
                  </form>
                </Box>
              ) : (
                <Box sx={{ height: "100%", width: "100%", p: 3 }}>
                  <Box
                    sx={{
                      width: "100%",
                      bgcolor: "white",
                      p: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </Box>
                  <Typography sx={{ fontSize: "35px", fontWeight: "600",color:"#306B4B" }}>
                    Welcome Back!
                  </Typography>
                  <Typography sx={{ fontSize: "22px", fontWeight: "500",color:"#306B4B" }}>
                    Sign In to Your Admin Dashboard
                  </Typography>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }}
                    style={{
                      width: "100%",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "1rem",
                      paddingTop: "30px",
                    }}
                  >
                    {isFieldInGrid("email") && (
                      <Box sx={{ width: "100%" }}>
                        <CustomTextField
                          label="Email Address"
                          type={
                            form?.fields?.find(
                              (item) => item.column_name === "email"
                            )?.data_type
                          }
                          placeholder="Email Address"
                          name="email"
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                          onKeyUp={formik.handleBlur}
                          errors={formik.touched.email && formik.errors.email}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value.trimStart() !== value) {
                              e.target.value = value.trimStart();
                            }
                            formik.handleChange(e);
                          }}
                          // isLoading={isLoading}
                        />
                      </Box>
                    )}
                    {isFieldInGrid("password") && (
                      <Box sx={{ width: "100%" }}>
                        <CustomTextField
                          label="Password"
                          type={
                            form?.fields?.find(
                              (item) => item.column_name === "password"
                            )?.data_type
                          }
                          placeholder="Password"
                          name="password"
                          value={formik.values.password}
                          onBlur={formik.handleBlur}
                          onKeyUp={formik.handleBlur}
                          errors={
                            formik.touched.password && formik.errors.password
                          }
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value.trimStart() !== value) {
                              e.target.value = value.trimStart();
                            }
                            formik.handleChange(e);
                          }}
                          // isLoading={isLoading}
                        />
                      </Box>
                    )}
                    <Link
                      onClick={() => setrForgotpassword(true)}
                      style={{
                        width: "100%",
                        textAlign: "right",
                        fontSize: "16px",
                        color: "black",
                        textDecoration: "none",
                        fontWeight: "500",
                      }}
                    >
                      Forgot Password?
                    </Link>
                    <Box
                      sx={{
                        width: "55%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CustomLoader
                        btnTitle="Submit"
                        loading={isLoading}
                        type="submit"
                      />
                    </Box>
                  </form>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginScreen;

import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCreative, Pagination, Navigation } from "swiper/modules";
import Profile from "../Assets/profile1.jpg";
import { IconButton } from "@mui/material";
import { PlayArrow, Pause, ArrowBack, ArrowForward } from "@mui/icons-material";

export default function FeedSwiper({ media }) {
  const videoRef = useRef(null); // Reference for the video element
  const [isPlaying, setIsPlaying] = useState(false); // State to track video play/pause status

  const handlePlayPause = (e) => {
    e.stopPropagation();
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  return (
    <>

        <Swiper
          grabCursor={true}
          pagination={{ clickable: true, el: ".custom-pagination" }}
          navigation={{
            prevEl: ".custom-prev", // Class for custom previous button
            nextEl: ".custom-next", // Class for custom next button
          }}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              origin: "left center",
              translate: ["-5%", 0, -200],
              rotate: [0, 100, 0],
            },
            next: {
              origin: "right center",
              translate: ["5%", 0, -200],
              rotate: [0, -100, 0],
            },
          }}
          modules={[EffectCreative, Pagination, Navigation]}
          className="mySwiper6"
        >
          {media?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <img
                  src={
                    `https://nextdoorapi.equitysoft.net${item.media}` || Profile
                  }
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "100%",
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

      <div
        className="custom-pagination"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      ></div>
    </>
  );
}

import "./App.css";
import RouteList from "./Common/RouteList";
import "react-loading-skeleton/dist/skeleton.css";
import { useContext, useEffect } from "react";
import { MyContext } from "./Context/UiContext";
import AxiosInstance from "./Config/AxiosInstance";
import { ApiList } from "./Config/ApiList";
function App() {
  const { setState } = useContext(MyContext);

  const forms = async () => {
    try {
      const response = await AxiosInstance.get(ApiList.GET_FORMS);
      if (response?.data?.s === 1) {
        setState(response?.data?.r);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    forms();
  }, []);
  return (
    <>
      <RouteList />
    </>
  );
}

export default App;

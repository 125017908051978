import { Box, Grid, TablePagination } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import NoDataImg from "../Assets/nodata.svg";
import CustomPagination from "./Pagnination/Pagnination";
import { styled } from "@mui/system";
const LeftAlignedCell = styled("div")(({ theme }) => ({
  textAlign: "left",
}));
const NoDataOverlay = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <img src={NoDataImg} alt="No Data" style={{ width: "200px" }} />
  </Box>
);

const TableContainer = ({
  rows,
  columns,
  loading,
  // page,
  // totalData,
  // rowsPerPage,
  // PAGE_SIZE_OPTIONS,
  // handlePageChange,
  // handleRowsPerPageChange,
  // hideFooter,
  pageSize,
  page,
  handlePageChange,
  totalData,
  sx = {},
}) => {
  const centeredColumns = columns?.map((column) => ({
    ...column,
    headerAlign: column.headerClassName ? undefined : "center",
    cellClassName: column.cellClassName || "centered-cell",
  }));
  return (
    <Box
      sx={{
        width: "100%",
        overflow: "auto",
        borderRadius: "5px 5px",
        height: "100%",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sx={{ height: "calc(100vh - 140px)", ...sx }}>
          <DataGrid
            rows={rows}
            columns={centeredColumns}
            pageSize={pageSize}
            pagination
            paginationMode="server"
            onPageChange={(newPage) => handlePageChange(newPage)}
            rowsPerPageOptions={[10]}
            rowCount={totalData}
            loading={loading}
            disableColumnResize
            disableColumnSorting={true}
            disableColumnMenu
            hideFooter
            slots={{ noRowsOverlay: NoDataOverlay }}
            disableRowSelectionOnClick
            sx={{
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#306B4B",
                color: "white",
                borderRadius: "0px",
                // borderLeft: "1px solid #292D3280",
                fontFamily: "Outfit",
                "&:first-of-type": {
                  borderLeft: "0px",
                },
                "&:last-of-type": {
                  borderRight: "0px",
                },
                textAlign: "center",
              },
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "0px",
                border: "1px solid #0000003D",
                fontFamily: "Outfit",
              },
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
              "& .leftAlign": {
                textAlign: "left !important",
                justifyContent: "flex-start",
              },
              "& .leftAlignHeader": {
                textAlign: "left !important",
              },
              ".MuiDataGrid-cell:focus": { outline: "none" },
              ".MuiDataGrid-columnHeader:focus": { outline: "none" },
            }}
            components={{
              Cell: (params) => {
                if (params.field === "email") {
                  return <LeftAlignedCell>{params.value}</LeftAlignedCell>;
                }
                return params.value;
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomPagination
            page={page}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
            totalData={totalData}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableContainer;

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PathList from "./PathList";
import LoginScreen from "../Screens/LoginScreen";
import Layout from "../Screens/Layout/Layout";
import { Middleware } from "../Config/MiddleWare";
import UserManagement from "../Screens/UserManagement/UserManagement";
import DetailsScreen from "../Screens/DetailsScreen/DetailsScreen";


const RouteList = () => {
  
  return (
    <Routes>
      <Route path={PathList.Login} element={<LoginScreen />} />
      <Route
        path={PathList.UserManagement}
        element={
          <Middleware children={<Layout element={<UserManagement />} />} />
        }
      />
      <Route
        path={PathList.DetailsScreen}
        element={
          <Middleware children={<Layout element={<DetailsScreen />} />} />
        }
      />
    </Routes>
  );
};

export default RouteList;

import {
  Box,
  Typography,
  Avatar,
  Tooltip,
  IconButton,
  Modal,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { MyContext } from "../Context/UiContext";
import Dummy from "../Assets/User.webp";
import TableContainer from "./TableContainer";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import AxiosInstance from "../Config/AxiosInstance";
import CustomSearchBox from "./CustomSearch";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import PathList from "../Common/PathList";

const splitOnFirstEquals = (str) => {
  const indexOfEquals = str.indexOf("=");
  if (indexOfEquals !== -1) {
    const key = str.substring(0, indexOfEquals).trim();
    const value = str.substring(indexOfEquals + 1).trim();
    return { key, value };
  } else {
    return { key: str, value: "" };
  }
};

const decodeApiParamsToObject = (queryString) => {
  const params = {};
  if (queryString) {
    queryString.split("&").forEach((param) => {
      const data = splitOnFirstEquals(param);
      params[data.key] = data.value;
    });
  }
  return params;
};

const FormBuilder = ({
  form,
  formData,
  page,
  totalData,
  pageSize,
  handlePageChange,
  loading,
  handleSearch,
  searchTerm,
  fetchUserList,
}) => {
  const navigate = useNavigate();

  const handleActionClick = (action, dataId, event) => {
    event.preventDefault();
    const clickedUser = formData.find(
      (item) => item[form.primary_column] === dataId
    );
    if (!clickedUser) {
      toast.error("User data not found");
      return;
    }
    const currentStatus = clickedUser[action.column_name];
    const isToggleStatusAction =
      action?.label?.toLowerCase() === "activate/deactivate";
    const confirmationTitle = action?.title;

    if (action?.label === "View") {
      navigate(PathList?.DetailsScreen, {
        state: {
          feed_id: dataId,
          parentId: form?.id,
        },
      });
      return;
    }

    Swal.fire({
      title: confirmationTitle,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let apiParams = action.arg === null ? action?.api_prm : action?.arg;
        if (isToggleStatusAction && currentStatus !== undefined) {
          const newStatus = currentStatus == 0 ? 1 : 0;
          apiParams = apiParams.replace("?", newStatus);
        }
        apiParams = apiParams?.replace(/\?$/, dataId);
        const requestBody = decodeApiParamsToObject(apiParams);
        if (action?.arg !== null) {
          requestBody.prms = dataId;
        }
        const method = action.api_method?.toLowerCase();
        try {
          const response = await AxiosInstance[method](action.api, requestBody);
          if (response?.data?.s === 1) {
            toast.success(response?.data?.m);
          }
          fetchUserList();
        } catch (error) {
          console.error("API call failed:", error);
          toast.error(error);
        }
      }
    });
  };
  const { setSortDirection } = useContext(MyContext);
  const [selectedFieldId, setSelectedFieldId] = useState(
    form?.order?.[0]?.id || ""
  );

  const { setFilter } = useContext(MyContext);
  const [isDescending, setIsDescending] = useState(false);
  const handleSort = (direction) => {
    setIsDescending(direction === "desc");
    if (selectedFieldId) {
      const selectedFieldObj = form?.order?.find(
        (item) => item.id === Number(selectedFieldId)
      );
      if (selectedFieldObj) {
        setSortDirection({ field: selectedFieldObj.value, direction });
      }
    }
  };
  const handleFieldChange = (event) => {
    setSelectedFieldId(event.target.value);
  };
  const handleFilterChange = (event) => {
    form.filters
      .filter((item) => item.type === "drop")
      .map((filter) => {
        const options = JSON.parse(filter.value);
        console.log(options);
        const selectedOptionData = options?.find(
          (option) => option?.id === Number(event)
        );
        setFilter(selectedOptionData);
      });
  };
  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const handleImageClick = (url) => {
    setImageUrl(url);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const columns = form?.fields?.map((field, index) => ({
    field: field.column_name,
    headerName: field.display_name,
    minWidth: field?.position === 1 ? 60 : field?.position === 2 ? 130 : 200,
    maxWidth: 300,
    flex: index + 1,
    renderCell: (params) => {
      if (field?.column_name === "profile_img") {
        const imageUrl = params?.row?.profile_img;
        return (
          <Avatar
            sx={{
              width: "40px",
              height: "40px",
              objectFit: "contain",
              cursor: "pointer",
            }}
            // onClick={() =>
            //   handleImageClick(`https://nextdoorapi.equitysoft.net${imageUrl}`)
            // }
          >
            <img
              src={`https://nextdoorapi.equitysoft.net${imageUrl}`}
              alt="Profile"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                objectFit: "cover",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = Dummy;
              }}
            />
          </Avatar>
        );
      }
      if (field?.column_name === "media") {
        const media = params?.row?.media?.[0]?.media;
        return (
          <Avatar
            sx={{
              width: "40px",
              height: "40px",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={() =>
              handleImageClick(`https://nextdoorapi.equitysoft.net${media}`)
            }
          >
            <img
              src={`https://nextdoorapi.equitysoft.net${media}`}
              alt="media"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                objectFit: "cover",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = Dummy;
              }}
            />
          </Avatar>
        );
      }
      if (field?.column_name === "caption") {
        const caption = params?.row?.caption || "--";
        const truncatedCaption =
          caption.length > 27 ? `${caption.substring(0, 27)}...` : caption;
        return (
          <Tooltip title={params?.row?.caption}>{truncatedCaption}</Tooltip>
        );
      }
      if (field?.column_name === "email") {
        const email = params?.row?.email || "--";
        const truncatedCaption =
          email.length > 20 ? `${email.substring(0, 20)}...` : email;
        return <Tooltip title={params?.row?.email}>{truncatedCaption}</Tooltip>;
      }
      if (field?.column_name === "created_at") {
        return (
          <Typography>
            {moment(params?.row?.created_at).format("DD-MM-YYYY ,  h:mm a")}
          </Typography>
        );
      }
    },
  }));

  if (form?.actions?.length > 0) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            {form.actions.map((action, index) => {
              const isToggleStatusAction =
                action?.label === "Activate/Deactivate";
              const currentStatus = formData.map(
                (item) => item[action.column_name]
              );
              const buttonColor =
                isToggleStatusAction && currentStatus === 0 ? "green" : "red";

              return (
                <Tooltip title={action?.label} key={index}>
                  <IconButton
                    sx={{ color: buttonColor }}
                    onClick={(event) =>
                      handleActionClick(action, params.row.id, event)
                    }
                  >
                    <i className={action?.icon} />
                  </IconButton>
                </Tooltip>
              );
            })}
          </Box>
        );
      },
    });
  }

  const rows = formData?.map((data, index) => {
    const row = { id: data?.[form?.primary_column] || index + 1 };
    form.fields?.forEach((field) => {
      if (field.column_name === "serial_number") {
        row[field.column_name] = index + 1 + page * pageSize;
      } else {
        row[field.column_name] = data[field.column_name];
      }
    });
    return row;
  });

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
        <Box
          onClick={handleCloseModal} // Close modal on outside click
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            sx={{ position: "relative" }}
          >
            <img
              src={imageUrl}
              alt="Popup"
              style={{ maxWidth: "100%", maxHeight: "100%",borderRadius:"10px" }}
            />
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: "absolute", top: -50, right: 0, color: "white" }}
            >
              X
            </IconButton>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          pb: 2,
          gap: { xs: 2, lg: 0 },
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: "28px", md: "30px" },
              color: "#306B4B",
              fontWeight: "bold",
            }}
          >
            {form?.form_name || "Form"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          {form?.filters?.length > 0 &&
            form.filters.some((item) => item.type === "search") && (
              <Box sx={{ width: "100%" }}>
                <CustomSearchBox
                  placeholder={"Search"}
                  onChange={handleSearch}
                />
              </Box>
            )}
          {form?.filters?.length > 0 && (
            <Box sx={{ width: "100%" }}>
              {form.filters
                .filter((item) => item.type === "drop")
                .map((filter) => {
                  const options = JSON.parse(filter.value);
                  return (
                    <select
                      key={filter.id}
                      style={{
                        padding: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#F5F5F5",
                        border: "none",
                        width: "100%",
                        outline: "none",
                        height: "50px",
                        borderRadius: "8px",
                      }}
                      onChange={(event) =>
                        handleFilterChange(event.target.value)
                      }
                    >
                      {options.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  );
                })}
            </Box>
          )}
          {form?.order?.length > 0 && (
            <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
              <select
                value={selectedFieldId}
                onChange={handleFieldChange}
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  backgroundColor: "#F5F5F5",
                  border: "none",
                  width: "150px",
                  outline: "none",
                  height: "50px",
                  borderRadius: "8px",
                }}
              >
                {form.order.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.label}
                  </option>
                ))}
              </select>
              {isDescending ? (
                <ArrowUpwardIcon
                  onClick={() => handleSort("asc")}
                  style={{
                    cursor: "pointer",
                    color: "black",
                    marginLeft: "10px",
                  }}
                />
              ) : (
                <ArrowDownwardIcon
                  onClick={() => handleSort("desc")}
                  style={{
                    cursor: "pointer",
                    color: "black",
                    marginLeft: "5px",
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TableContainer
          sx={{ width: "100%" }}
          rows={rows}
          columns={columns}
          page={page}
          loading={loading}
          totalData={totalData}
          pageSize={pageSize}
          handlePageChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};

export default FormBuilder;

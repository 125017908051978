import React, { createContext, useState } from "react";

export const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [state, setState] = useState([]);
  const [form, setForm] = useState(null);
  const [sortDirection, setSortDirection] = useState({});
  const [filter, setFilter] = useState({});
  return (
    <MyContext.Provider
      value={{
        state,
        setState,
        form,
        setForm,
        sortDirection,
        setSortDirection,
        filter,
        setFilter,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

import React from "react";
import { Box, Pagination } from "@mui/material";

const CustomPagination = ({ page, pageSize, handlePageChange, totalData }) => {
  const totalPages = Math.ceil(totalData / pageSize);
  const handleChange = (event, value) => {
    handlePageChange(value -1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px",
      }}
    >
      <Pagination
        count={totalPages}
        page={page + 1}
        
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
        sx={{
          '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
              backgroundColor: '#306B4B',
              color: 'white',
              border: '1px solid #306B4B',
              '&:hover': {
                backgroundColor: '#306B4B',
                color: 'white',
              },
            },
          },
          '& .MuiPaginationItem-page': {
            '&:hover': {
              backgroundColor: '#306B4B',
            },
          },
          '& .MuiPaginationItem-previousNext': {
            color: '#000E72',
            '&.Mui-disabled': {
              color: '#C3D5E5',
            },
          },
        }}
      />
    </Box>
  );
};

export default CustomPagination;

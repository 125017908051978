import React, { useContext, useState } from "react";
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavMenuList } from "./MenuList";
import Swal from "sweetalert2";
import { MyContext } from "../../Context/UiContext";
import logo from "../../Assets/logo.png";
// import PathList from "../../Common/PathList";

const drawerWidth = 230;

function Layout({ element }) {
  const { form } = useContext(MyContext);
  const { state } = useContext(MyContext);
  const data = state;
  // const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      text: "You will be redirected to login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate("/");
      }
    });
  };

  const handleModuleClick = (id) => {
    navigate("/user", {
      state: {
        id: id,
      },
    });
  };

  const drawer = (
    <Box
      sx={{
        bgcolor: "#ffffff",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ p: 2, textAlign: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: "150px", height: "150px" }}
          />
        </Box>
      </Box>

      <List sx={{ flex: 1, overflowY: "auto" }}>
        {
          Array.isArray(data)
            ? data
                .filter(
                  (item) =>
                    item?.form_name !== "Login" &&
                    item?.form_name !== "Reported Feed Details"
                )
                .map((item, i) => {
                  return (
                    <ListItem
                      key={i}
                      disablePadding
                      onClick={() => handleModuleClick(item?.id)}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          textDecoration: "none",
                        }}
                      >
                        <ListItemButton
                          sx={{
                            m: 0.5,
                            backgroundColor:
                              form?.id === item?.id ? "#306B4B" : "inherit",
                            "&:hover": {
                              backgroundColor: "#63A47C",
                              borderRadius: "8px",
                              color: "#ffffff",
                            },
                            borderRadius: "8px",
                          }}
                        >
                          <ListItemText
                            primary={item?.form_name}
                            sx={{
                              color: form?.id === item?.id ? "white" : "black",
                              "&:hover": {
                                color: "#ffffff",
                              },
                              "& .MuiTypography-root": { fontWeight: 500 },
                            }}
                          />
                        </ListItemButton>
                      </Box>
                    </ListItem>
                  );
                })
            : null /* Handle non-array or loading state */
        }
      </List>

      <Button
        onClick={handleLogout}
        startIcon={<LogoutIcon />}
        sx={{
          color: "#306B4B",
          justifyContent: "flex-start",
          px: 3,
          py: 1.5,
          fontSize: "20px",
          fontWeight: 600,
          textTransform: "none",
        }}
      >
        Logout
      </Button>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ display: { sm: "none" } }}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": { width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar sx={{ display: { sm: "none" } }} />
        {element}
      </Box>
    </Box>
  );
}

export default Layout;

import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Tooltip,
  Typography,
  IconButton,
  Pagination,
  Button,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
import FeedSwiper from "./FeedSwiper";
import user from "../Assets/User.webp";
import Loader from "./Loader";
import moment from "moment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import PathList from "../Common/PathList";
import AxiosInstance from "../Config/AxiosInstance";
const GridViewForm = ({ formData, form, count, page, onChange, loading,fetchUserList }) => {
  const data = formData;
  const grid = form?.grid;
  const boxRef = useRef(null);
const navigate = useNavigate()
  const isFieldInGrid = (fieldName) => {
    return grid?.some((gridItem) => gridItem.column_name === fieldName);
  };
  // const splitOnFirstEquals = (str) => {
  //   const indexOfEquals = str.indexOf("=");
  //   if (indexOfEquals !== -1) {
  //     const key = str.substring(0, indexOfEquals).trim();
  //     const value = str.substring(indexOfEquals + 1).trim();
  //     return { key, value };
  //   } else {
  //     return { key: str, value: "" };
  //   }
  // };
  
  // const decodeApiParamsToObject = (queryString) => {
  //   const params = {};
  //   if (queryString) {
  //     queryString.split("&").forEach((param) => {
  //       const data = splitOnFirstEquals(param);
  //       params[data.key] = data.value;
  //     });
  //   }
  //   return params;
  // };
  const handleActionClick = (action, dataId, event) => {
    event.preventDefault();
    const clickedUser = formData.find(
      (item) => item[form.primary_column] === dataId
    );
    if (!clickedUser) {
      toast.error("User data not found");
      return;
    }
    const currentStatus = clickedUser[action.column_name];
    const isToggleStatusAction =
      action?.label?.toLowerCase() === "activate/deactivate";
    const confirmationTitle = action?.title;

    if (action?.label === "View") {
      navigate(PathList?.DetailsScreen, {
        state: {
          feed_id: dataId,
          parentId: form?.id,
        },
      });
      return;
    }
    // let apiParams = action.arg === null ? action?.api_prm : action?.arg;
    console.log(action?.api)
    // return
    Swal.fire({
      title: confirmationTitle,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let apiParams = action.arg === null ? action?.api_prm : action?.arg;
        
        // if (isToggleStatusAction && currentStatus !== undefined) {
        //   const newStatus = currentStatus == 1 ? -1 : 1;
        //   apiParams = apiParams.replace("?", newStatus);
        // }
        apiParams = apiParams?.replace(":id", `${dataId}`);
        // const requestBody = decodeApiParamsToObject(apiParams);
        // if (action?.arg !== null) {
        //   requestBody.prms = dataId;
        // }
        // console.log(requestBody)
        const method = action.api_method?.toLowerCase();
        try {
          const response = await AxiosInstance[method](`${action.api}/${apiParams}`);
          if (response?.data?.s === 1) {
            toast.success(response?.data?.m);
          }
          fetchUserList();
        } catch (error) {
          console.error("API call failed:", error);
          toast.error(error);
        }
      }
    });
  };

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTo(0, 0);
    }
  }, [data]);

  return (
    <>
      <Box sx={{ width: "100%", height: "calc(100vh - 24px)" }}>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: "28px", md: "30px" },
              color: "#306B4B",
              fontWeight: "bold",
            }}
          >
            {form?.form_name || "Form"}
          </Typography>
        </Box>
        {loading ? (
          <Box sx={{ height: "calc(100vh - 150px)" }}>
            <Loader />
          </Box>
        ) : (
          <Box
            ref={boxRef}
            sx={{
              width: "100%",
              maxHeight: "calc(100vh - 150px)",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              overflow: "auto",
            }}
          >
            {data?.map((item, index) => {
              const caption = item?.caption || "--";
              const truncatedCaption =
                caption.length > 60
                  ? `${caption.substring(0, 60)}...`
                  : caption;
              const location = item?.location || "--";
              const truncatedLocation =
                location.length > 25
                  ? `${location.substring(0, 25)}...`
                  : location;

              return (
                <Box
                  key={index}
                  sx={{
                    width: "350px",
                    m: 3,
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{ width: "100%", gap: "10px", p: 1, display: "flex" }}
                  >
                    {isFieldInGrid("profile_img") && (
                      <Avatar
                        sx={{
                          width: "50px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      >
                        <img
                          src={
                            item?.profile_img
                              ? `https://nextdoorapi.equitysoft.net${item?.profile_img}`
                              : user
                          }
                          alt=""
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Avatar>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box>
                        {isFieldInGrid("user_name") && (
                          <Typography sx={{ fontSize: "18px", color: "black" }}>
                            {item?.f_name} {item?.l_name}
                          </Typography>
                        )}
                        {isFieldInGrid("location") && (
                          <Tooltip title={item?.location}>
                            <Typography
                              sx={{ fontSize: "14px", color: "black" }}
                            >
                              {truncatedLocation}
                            </Typography>
                          </Tooltip>
                        )}
                      </Box>
                      {form?.actions?.length > 0 && (
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton
                                variant="contained"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                {form?.actions?.map((actionItem, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      sx={{ width: "200px" }}
                                      onClick={(event) => {
                                        handleActionClick(
                                          actionItem,
                                          item?.id,
                                          event
                                        );
                                        popupState.close()
                                      }}
                                    >
                                      <i className={actionItem?.icon}></i>
                                      <Typography sx={{ pl: 2 }}>
                                        {actionItem?.label}
                                      </Typography>
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      )}
                    </Box>
                  </Box>
                  {isFieldInGrid("media") && (
                    <Box
                      sx={{
                        width: "100%",
                        height: "248px",
                        backgroundColor: "#f5f5f5",
                      }}
                    >
                      <FeedSwiper media={item?.media} />
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      height: "120px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "30px",
                        alignItems: "center",
                        p: 1,
                      }}
                    >
                      {isFieldInGrid("like_count") && (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <FavoriteIcon fontSize="smaller" />
                          <Typography sx={{ fontSize: "12px" }}>
                            {item?.like_count}
                          </Typography>
                        </Box>
                      )}
                      {isFieldInGrid("comment_count") && (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <CommentIcon fontSize="smaller" />
                          <Typography sx={{ fontSize: "12px" }}>
                            {item?.comment_count}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "calc(130px - 34px)",
                      }}
                    >
                      {isFieldInGrid("caption") && (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "30px",
                            alignItems: "center",
                            px: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: "16px", color: "black" }}>
                            <Tooltip title={item?.caption}>
                              {truncatedCaption}
                            </Tooltip>
                          </Typography>
                        </Box>
                      )}
                      {isFieldInGrid("created_at") && (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "30px",
                            alignItems: "center",
                            px: 1,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", color: "#00000060" }}
                          >
                            {moment(item?.created_at).fromNow()}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}

        <Box display="flex" justifyContent="end" width="100%" mt={0} p={2}>
          <Pagination
            count={count}
            page={page}
            onChange={onChange}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  backgroundColor: "#306B4B",
                  color: "white",
                  border: "1px solid #306B4B",
                  "&:hover": {
                    backgroundColor: "#306B4B",
                    color: "white",
                  },
                },
              },
              "& .MuiPaginationItem-page": {
                "&:hover": {
                  backgroundColor: "#306B4B",
                },
              },
              "& .MuiPaginationItem-previousNext": {
                color: "#000E72",
                "&.Mui-disabled": {
                  color: "#C3D5E5",
                },
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default GridViewForm;

import React, { useContext, useEffect, useState } from "react";
import FormBuilder from "../../Components/FormBuilder";
import { MyContext } from "../../Context/UiContext";
import { Box, Pagination } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../Config/AxiosInstance";
import GridViewForm from "../../Components/GridViewForm";

const UserManagement = () => {
  const pageSize = 30;
  const { sortDirection } = useContext(MyContext);
  const location = useLocation();
  const { state, filter } = useContext(MyContext);
  const { setForm } = useContext(MyContext);
  const selectedId = location?.state?.id || 1;
  const form = Array.isArray(state)
    ? state.find((form) => form.id === selectedId)
    : null;
  setForm(form);
  const [formData, setFormData] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    setPage(0);
  }, [selectedId]);

  const getUserList = async (params) => {
    if (!form) return;
    setLoading(true);
    try {
      const method = form?.api_method?.toLowerCase();
      const apiEndpoint = form?.api;
      const axiosConfig = method === "get" ? { params } : { data: params };
      const response = await AxiosInstance[method](apiEndpoint, axiosConfig);
      if (response?.data?.s === 1) {
        setFormData(response?.data?.r || []);
        if (params.skip === 0) {
          const totalCount = response?.data?.total || 0;
          localStorage.setItem("totalCount", totalCount);
          setTotalData(totalCount);
        } else {
          const totalCountFromStorage = localStorage.getItem("totalCount");
          setTotalData(
            totalCountFromStorage ? parseInt(totalCountFromStorage, 10) : 0
          );
        }
        setLoading(false);
      } else {
        setFormData([]);
        setTotalData(0);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const fetchUserList = () => {
    if (!form) return;
    const skip = page * pageSize; // Update the skip based on the current page
    const total = `1`;
    const order =
      sortDirection?.field && sortDirection?.direction
        ? `${sortDirection.field} ${sortDirection.direction}`
        : "created_at desc";

    let updatedDynamicParams = { ...dynamicParams };

    updatedDynamicParams.skip = skip;

    if (searchTerm) {
      const searchCondition = form?.filters?.find(
        (item) => item?.type === "search"
      )?.arg;
      const updatedSearchArg = searchCondition.replace(/\$1/g, searchTerm);
      console.log(updatedSearchArg)
      updatedDynamicParams.arg = `${updatedDynamicParams.arg} ${updatedSearchArg}`;
    } else if (filter?.label !== "All") {
      const filterArg = form?.filters?.find(
        (item) => item?.type === "drop"
      )?.arg;
      const updatedFilter = filterArg?.replace("$1", filter?.status);
      if (filter?.status === undefined) {
        updatedDynamicParams.arg = dynamicParams.arg;
      } else {
        updatedDynamicParams.arg = `${dynamicParams.arg} ${updatedFilter}`;
      }
    }
    updatedDynamicParams.order = order;
    const params = {
      skip,
      limit: pageSize,
      total: total,
      ...updatedDynamicParams,
    };
    getUserList(params);
  };

  function splitOnFirstEquals(str) {
    const indexOfEquals = str.indexOf("=");
    if (indexOfEquals !== -1) {
      const key = str.substring(0, indexOfEquals).trim();
      const value = str.substring(indexOfEquals + 1).trim();
      return { key, value };
    } else {
      return { key: str, value: "" };
    }
  }

  const decodeApiParamsToObject = (queryString) => {
    const params = {};
    if (queryString) {
      queryString.split("&").forEach((param) => {
        const data = splitOnFirstEquals(param);
        params[data.key] = data.value;
      });
    }
    return params;
  };
  const dynamicParams = form ? decodeApiParamsToObject(form.api_prm || "") : {};

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handlePageChangeGrid = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0)
  };

  useEffect(() => {
    if (form) {
      fetchUserList();
    }
  }, [form, page, searchTerm, sortDirection, filter]);

  return (
    <Box sx={{ width: "100%", height: "100vh", p: 3 }}>
      {form?.form_type === "Table" && (
        <FormBuilder
          form={form}
          formData={formData}
          page={page}
          loading={loading}
          totalData={totalData}
          pageSize={pageSize}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          fetchUserList={fetchUserList}
          handlePageChange={handlePageChange}
        />
      )}
      {form?.form_type === "Grid" && (
        <GridViewForm
          formData={formData}
          form={form}
          loading={loading}
          count={Math.ceil(totalData / pageSize)}
          page={page + 1}
          fetchUserList={fetchUserList}
          onChange={handlePageChangeGrid}
        />
      )}
    </Box>
  );
};

export default UserManagement;
